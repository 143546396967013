import { initializeApp } from "firebase/app";
import { getAuth,RecaptchaVerifier ,signInWithPhoneNumber ,signInWithPopup,GoogleAuthProvider ,updateProfile , getAdditionalUserInfo, onAuthStateChanged,signOut ,signInWithEmailAndPassword ,createUserWithEmailAndPassword } from "firebase/auth";
import {getFirestore, collection, doc, setDoc} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use


const firebaseConfig = {
    apiKey: "AIzaSyAWfOHaEHLAq-CooUocKyXn064ZBs7wDGU",
    authDomain: "fakenail-in.firebaseapp.com",
    projectId: "fakenail-in",
    storageBucket: "fakenail-in.appspot.com",
    messagingSenderId: "387250771904",
    appId: "1:387250771904:web:1c4d1d54f98f8d67635a25",
    measurementId: "G-9FMY9SMHFZ"
};

// Initialize Firebase
const authStateChecked = document.cookie.includes("authStateChecked=true");
const app = await initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db =  getFirestore(app);
var loginBtn;
var logoutBtn;
if (!authStateChecked) {
    var auth = await getAuth(app);

}
else {
    var auth =  getAuth(app);

}

// code for login using otp
window.recaptchaVerifier = new RecaptchaVerifier(auth, 'loginbutton', {
    'size': 'invisible',
    'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log("step1");

        console.log("step2");

    }
});









window.googleLogin = function (){
    console.log("google button pressed");
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
        .then((result) => {
            console.log("user sign in completed");
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;

            try{
                let details = getAdditionalUserInfo(result)
                if(details.isNewUser){
                    try{
                        UploadUserInfo(user.uid,user.email,user.phoneNumber,user.displayName);
                        createCart(user.uid);
                    }catch (error){
                        console.log("User Info Not updated in storage",error.toString());
                    }
                }

            }catch (error){
                console.log("Error in finding new user or not",error.toString());
            }
            changedAuth();

            var closeButton = document.getElementById('closemodal');
            closeButton.click();
        }).catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;
        let email = error.customData.email;
        let credential = GoogleAuthProvider.credentialFromError(error);
    });

}



window.logout = function() {
    // do something
    signOut(auth).then(() => {
        // Sign-out successful.
        document.cookie = "uniqueBrowserId=;" ;
        loginBtn.style.display = 'inline-block';
        logoutBtn.style.display = 'none';
        console.log("Sign-out successful. ");
        changedAuth();

    }).catch((error) => {
        // An error happened.
        console.log("Sign-out Error. ",error.toString());

    });
}





window.login = function(button) {
    button.textContent = "Loading..."
    button.disabled = true;
    var phoneNumber = document.getElementById('loginNumber').value;
    phoneNumber = "+91" + phoneNumber;
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
            console.log("step4");
            window.confirmationResult = confirmationResult;
            var checkbox = document.getElementById("reg-log");
            checkbox.click();
            button.textContent = "SUBMIT";
            button.disabled = false;


            // ...
        }).catch((error) => {
            alert(error);
            button.textContent = "SUBMIT";
            button.disabled = false;

        // ...
    });
}
window.chgnum = function (){
    var checkbox = document.getElementById("reg-log");
    checkbox.click();
}
window.register = function (button) {
    button.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Loading...';
    button.disabled = true;
    var code = document.getElementById('OTP').value;

    confirmationResult.confirm(code).then((result) => {
        const user = result.user;
        console.log(user.uid);
        var closeButton = document.getElementById('closemodal');
        closeButton.click();
        alert('User Created successfully!');
        button.innerHTML = 'Register';
        button.disabled = false;
        try{
            updateProfile(user, {
                displayName: name
            }).then(() => {
                console.log( "Profile updated!");

            }).catch((error) => {
                console.log( "Profile An error occurred!");

            });
        }catch (error){
            console.log("error in user profile update",error);
        }

        try{
            UploadUserInfo(user.uid,user.email,user.phoneNumber,name);
            createCart(user.uid);
            changedAuth();

        }catch (error){
            console.log("User Info Not updated in storage",error.toString());
        }
    }).catch((error) => {
        alert(error);
            let errorCode = error.code;
            let errorMessage = error.message;
            alert(errorMessage);
            button.innerHTML = 'Register';
            button.disabled = false;
            // ..
        });
}

async function UploadUserInfo(uid,email,mobile,name) {
    try {
        let productRef = collection(db, "users");

        let docRef =  setDoc(doc(productRef, uid), {
            'UID': uid,
            'UserEmail': email,
            'UserMobile': mobile,
            'UserName': name,
            'OrderHistory':[]
        });
    } catch (e) {
        console.error("Error adding User Data: ", e);
    }
}
async function createCart(uid) {
    try {
        let productRef = collection(db, "cart");

        let docRef =  setDoc(doc(productRef, uid), {

        });
    } catch (e) {
        console.error("Error adding User Data: ", e);
    }
}

window.goToProduct = async function (){
    var modalid = document.getElementById("modal-ID").textContent;
    window.location.href = `product.html?id=${modalid}`;

}
function generateUniqueId() {
    const timestamp = new Date().getTime();
    const randomNum = Math.floor(Math.random() * 10000);
    const uniqueId = `bid${timestamp}-${randomNum}`;
    return uniqueId;
}
function getCookieUid() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith("userUid" + '=')) {
            return cookie.substring("userUid".length + 1);
        }
    }
    return null;
}

// async function pushProductsToCart(uid,productlist) {
//
//     try {
//         const docRef = doc(db, "cart", uid.toString());
//         const docSnapshot = await getDoc(docRef);
//         if (docSnapshot.exists()) {
//             updateDoc(docRef, {ProcductCartList:arrayUnion(productlist)});
//         } else {
//             setDoc(docRef, {ProcductCartList: [productlist]});
//         }
//
//     } catch (e) {
//         console.error("Error adding document: ", e);
//     }
//
// }

window.addtocart = async function () {
    var cartmodaltitle = document.getElementById("modal-title").textContent;
    var cartmodalprice = document.getElementById("modalprice").dataset.mainPrice;
    var modalid = document.getElementById("modal-ID").textContent;
    var cartmodalImage = document.getElementById("modal-ID").dataset.ImageSrc;
    var datanailqty = document.getElementById("modal-ID").dataset.datanailqty;
    var removeValue = "";
    var values = {
        CartId:generateUniqueId(),
        ID: modalid,
        Title: cartmodaltitle,
        Image: cartmodalImage,
        Price: cartmodalprice,
        Quantity: datanailqty,
    };

    // if user logged in upload to firebase
    console.log("reached local storage");
    var storedProductsString = localStorage.getItem('products');
    if (storedProductsString !== null && storedProductsString.trim() !=="") {

        console.log("storedProductsString",storedProductsString);

        var storedProducts = await JSON.parse(storedProductsString);
        console.log(storedProducts);
    } else {
        var storedProducts = [];
        console.log('No products found in local storage.');
    }
    await storedProducts.push(values);
    console.log(storedProducts);
    var productsString = await JSON.stringify(storedProducts);
    await localStorage.setItem('products', productsString);

    window.location.href = 'cart.html';

}


function changedAuth(){
    onAuthStateChanged(auth, async (user) => { // this will run every time the user data changes
        console.log("checking state");
        const d = new Date();
        d.setTime(d.getTime() + (60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = "authStateChecked=true; "+expires;
        if (user) {
            console.log("user Found");
            document.cookie = "userState=true; "+expires;
            document.cookie = "userUid="+user.uid +"; "+expires;
            console.log("updated cookie 232");

            loginBtn.style.display = 'none';
            logoutBtn.style.display = 'inline-block';

        } else {
            console.log("No user Found");
            document.cookie = "userState=false; "+expires;
            document.cookie = "userUid=false; "+ expires;
            console.log("updated cookie 912");

            loginBtn.style.display = 'inline-block';
            logoutBtn.style.display = 'none';

        }

    });

}


document.addEventListener('DOMContentLoaded', async function() {
    loginBtn = document.querySelector('#loginbutton');
    logoutBtn = document.querySelector('#logoutbutton');
    // check if logged in or not for the first time
    const authStateChecked = document.cookie.includes("authStateChecked=true");
    const userState = document.cookie.includes("userState=true");

    if (!authStateChecked) {
        changedAuth();
        console.log("auth state not found hence checking")
    }
    else{
        if (!userState){
            console.log("auth already checked user not logged in")
            loginBtn.style.display = 'inline-block';
            logoutBtn.style.display = 'none';
        }
        else {
            console.log("auth already checked user logged in")
            loginBtn.style.display = 'none';
            logoutBtn.style.display = 'inline-block';
        }
    }


});

window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById("navbarHeaderDiv").classList.add("navbar-dark");
        document.getElementById("navbarHeaderDiv").classList.add("bg-dark");
        document.getElementById("loginbutton").classList.add("btn-dark");
        document.getElementById("accountbutton").classList.add("btn-dark");
    } else {
        document.getElementById("navbarHeaderDiv").classList.remove("navbar-dark");
        document.getElementById("navbarHeaderDiv").classList.remove("bg-dark");
        document.getElementById("loginbutton").classList.remove("btn-dark");
        document.getElementById("accountbutton").classList.remove("btn-dark");

    }
}